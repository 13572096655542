<template>
  <div class="text-center">
    <v-icon v-if="icon" class="state-icon" large>{{ icon }}</v-icon>
    <div class="state-icon">
      <slot />
    </div>
    <h2 v-if="title" class="title my-4">{{ title }}</h2>
    <v-subheader v-if="message" class="justify-center message">
      {{ message }}
    </v-subheader>
    <v-btn
      v-if="actionText"
      color="#003650"
      class="my-5 white--text"
      @click="didClickAction()"
    >
      {{ actionText }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'EmptyStateCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    actionText: {
      type: String,
      default: ''
    }
  },
  methods: {
    didClickAction() {
      /**
       * Triggered when Call to Action button gets clicked
       * @event on-cta-click
       */
      this.$emit('on-cta-click')
    }
  }
}
</script>

<style>
.message.theme--light.v-subheader {
  max-width: 315px;
  margin: 0 auto;
}
.state-icon {
  font-size: 106px !important;
  color: #003650 !important;
}
.empty-state {
  min-height: 294px;
}
</style>
